import React from 'react';
import {useTranslation} from 'react-i18next';
import Layout from '../../../components/Layout';
import {Link} from 'gatsby';
import Seo from '../../../components/Seo';
import BreadCrumb from '../../../components/BreadCrumb';
import {withLocaleProvider} from '../../../locale/LocaleProvider';

export const TechnologicalChoisesPage = () => {
  const {t, i18n} = useTranslation();

  return (
    <>
      <Seo
        lang={i18n.language}
        title={t('technological-choices-1:seo:title')}
        description={t('technological-choices-1:seo:description')}
        url={t('technological-choices-1:seo:url')}
      />
      <Layout beforeFooter>
        <BreadCrumb
          routes={[
            {
              name: t('Nav:products:title'),
            },
            {
              name: t('Nav:products:link-1:title'),
              path: t('Nav:products:link-1:url'),
            },
            {
              name: t('Language-courses:contents:choice-2:text'),
              path: t('Language-courses:contents:choice-2:link-url'),
            },
          ]}
        />
        <div className="breadcrumbs-v3 img-v5">
          <div className="container text-center">
            <p>{t('technological-choices-1:intro:subtitle')}</p>
            <h1>{t('technological-choices-1:intro:title')}</h1>
          </div>
        </div>
        <div className="container content-sm">
          <div className="lead text-center">
            <p
              dangerouslySetInnerHTML={{
                __html: t('technological-choices-1:contents:text-1'),
              }}
            />
          </div>
          <p
            dangerouslySetInnerHTML={{
              __html: t('technological-choices-1:contents:text-2'),
            }}
          />
        </div>
        <div
          className="parallax-counter-v2 parallaxBg1"
          style={{backgroundPosition: '50% 51px'}}
        >
          <div className="container">
            <ul className="row list-row">
              <li className="col-sm-6 col-xs-12 sm-margin-bottom-30">
                <div className="counters rounded language-courses">
                  <Link
                    to={t('technological-choices-1:contents:choice-1:link-url')}
                  >
                    <h4 className="font-normal">
                      {t('technological-choices-1:contents:choice-1:text')}
                    </h4>
                  </Link>
                </div>
              </li>
              <li className="col-sm-6 col-xs-12">
                <div className="counters rounded language-courses">
                  <Link
                    to={t('technological-choices-1:contents:choice-2:link-url')}
                  >
                    <h4 className="font-normal">
                      {t('technological-choices-1:contents:choice-2:text')}
                    </h4>
                  </Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default withLocaleProvider('it', TechnologicalChoisesPage);
